import { SectionV3WithSteps, ResolvedStep, DateFrame } from '../types/v3Types';
import type { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { IParticipantSectionsContext } from '../contexts/ParticipantSections/ParticipantSectionsContext';
import { toParticipantStepV3 } from '../contexts/ParticipantStepsDataProvider/toParticipantSteps';
import { getStartDate } from './GetStartDate';
import { isFlexible } from './isFlexible';
import { isSelfPaced } from './isSelfPaced';

export function toParticipantSectionsV3({
  sections,
  resolvedSteps,
  challenge,
  dateFrame,
}: {
  sections: SectionV3WithSteps[];
  challenge: Challenge;
  dateFrame?: DateFrame;
  resolvedSteps: ResolvedStep[];
}): {
  sections: IParticipantSectionsContext['listParticipantSections'];
  steps: IParticipantSectionsContext['participantSteps']['steps'];
} {
  const resolvedById = new Map(
    resolvedSteps.map((step) => [step.programStepId, step]),
  );
  const initialDate = isFlexible(challenge)
    ? new Date(dateFrame.start)
    : getStartDate(challenge);
  const participantSections = sections
    .sort((a, b) => {
      return isSelfPaced(challenge)
        ? a.ordering - b.ordering
        : a.delayInDays - b.delayInDays;
    })
    .map((section) => {
      const steps = section.steps.map((step) => {
        const resolvedStep = resolvedById.get(step.id);
        return toParticipantStepV3({
          step,
          resolvedStep,
          dateFrame: !initialDate ? dateFrame : undefined,
          initialDate,
        });
      });
      return {
        id: section.id,
        source: section,
        steps,
      };
    });
  const participantSteps = participantSections.flatMap(
    (section) => section.steps,
  );
  return { sections: participantSections, steps: participantSteps };
}
